'use strict'

import slick from 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';


jQuery(function () {
    //Set Slick Nav Classes
    $('.home-service-container-slick').on('init', function (event, slick, currentSlide, nextSlide) {
        if ($(slick.$slides[slick.$slides.length - 1]).hasClass('slick-active')) {
            $('.slick-nav-right').addClass('inactive');
        } else {
            $('.slick-nav-right').removeClass('inactive');
        }


        if ($(slick.$slides[0]).hasClass('slick-active')) {
            $('.slick-nav-left').addClass('inactive');
        } else {
            $('.slick-nav-left').removeClass('inactive');
        }
    });

    $('.home-service-container-slick').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        if ($(slick.$slides[slick.$slides.length - 1]).hasClass('slick-active')) {
            $('.slick-nav-right').addClass('inactive');
        } else {
            $('.slick-nav-right').removeClass('inactive');
        }


        if ($(slick.$slides[0]).hasClass('slick-active')) {
            $('.slick-nav-left').addClass('inactive');
        } else {
            $('.slick-nav-left').removeClass('inactive');
        }
    });

    //Init Slick
    $('.home-service-container-slick').slick({
        infinite: false,
        slidesToShow: 3,
        variableWidth: true,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 10000,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    autoplaySpeed: 3000,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    autoplaySpeed: 2000,
                }
            }
        ]
    });


    //Slick Nav
    $('.slick-nav-control').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('slick-nav-left')) {
            $('.home-service-container-slick').slick('slickPrev');
        }
        if ($(this).hasClass('slick-nav-right')) {
            $('.home-service-container-slick').slick('slickNext');
        }

    })

});