'use strict'

import Masonry from "masonry-layout";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const masonryPrepend = '<div class="grid-sizer"></div><div class="gutter-sizer"></div>';
const itemsContainer = $('.references-container');
const itemClass = '.reference';

const renderItems = items => {
    $(itemsContainer).prepend(masonryPrepend);
    itemsContainer.find('img').each(function () {
        let img = new Image();
        img.src = this.src
        img.onload = function () {
            initMasonry();
        };
    });
};

const initMasonry = () => {
    if (itemsContainer.length) {
        let msnry = new Masonry('.references-container', {
            columnWidth: '.grid-sizer',
            gutter: '.gutter-sizer',
            itemSelector: '.reference',
            resize: true,
            percentPosition: true
        });

        //msnry.on('layoutComplete', hidePreloader());
    }
};

jQuery(() => {
    renderItems();

    const lightbox = new PhotoSwipeLightbox({
        gallery: '.references-container',
        children: 'a',
        pswpModule: () => import('photoswipe')
    });
    lightbox.init();

})