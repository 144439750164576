'use strict';

const contactForm = document.getElementById("contact-form");

let renderErrors = (errors) => {
    for (let [field, error] of Object.entries(errors)) {
        const element = $('#contact-form').find('#' + field).closest('.form-element');
        element.addClass('error');
        element.find('.error-text').html(error);
    }
    $('#submit').addClass('inactive');
}

let clearError = (element) => {
    let parent = element.closest('.form-element');
    parent.removeClass('error');
    parent.find('.error-text').html('&nbsp;');
    if ($('.form-element.error').length == 0) {
        $('#submit').removeClass('inactive');
    }
}

let showPreloader = () => {
    $('#form-busy').fadeIn();
}
let hidePreloader = () => {
    $('#form-busy').fadeOut();
}

let showSuccessMessage = () => {
    $('#message-sent').addClass('isvis');
    setTimeout(function () {
        $('#message-sent').removeClass('isvis');
    }, 5000);
}

let resetForm = () => {
    contactForm.reset();
    $('#contact-form').find('.form-element').removeClass('active').find('.checkbox-vis').removeClass('selected');
}


function gtag_report_conversion(url) {
    var callback = function () {
        if (typeof (url) != 'undefined') {
           window.location = url;
        }
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-1008601977/DZ0oCKe61wEQ-Zb44AM',
        'event_callback': callback
    });
    return false;
}

let sendRequerst = (data) => {
    showPreloader();
    fetch('assets/template/dist/php/contact.php', {
        method: 'post',
        body: data
    }).then(response => {
        return response.text();
    }).then(responseString => {
        hidePreloader();
        const output = JSON.parse(responseString);
        if (output.error == 1) {
            renderErrors(output.errors);
        } else {
            if (output.mailSent == 1) {
                resetForm();
                showSuccessMessage();
                gtag_report_conversion();
            } else {
                alert('Etwas stimmt nicht. Das ist nicht Ihr Schuld :(');
            }
        }
    }).catch(error => {
        alert('Etwas stimmt nicht. Das ist nicht Ihr Schuld :(');
        console.log(error)
    })
}

contactForm.addEventListener('submit', e => {
    e.preventDefault();
    const formData = new FormData(contactForm);
    if (!$('#submit').hasClass('inactive')) {
        sendRequerst(formData);
    }
})

jQuery(function () {
    $('#contact-form').find('input,textarea').each(function () {
        $(this).on('focus', function () {
            $(this).closest('.form-element').addClass('active');
            clearError($(this));
        })
        $(this).on('blur', function () {
            if (!$(this).val()) {
                $(this).closest('.form-element').removeClass('active');
            }
        })
    })
    $('#contact-form').find('.form-element.checkbox').each(function () {
        $(this).find('input').on('focus', function () {
            $(this).closest('.form-element').find('.checkbox-vis').addClass('focus');
        })
        $(this).find('input').on('blur', function () {
            $(this).closest('.form-element').find('.checkbox-vis').removeClass('focus');
        })

        $(this).find('input').change(function () {
            if (this.checked) {
                $(this).closest('.form-element').find('.checkbox-vis').addClass('selected');
            } else {
                $(this).closest('.form-element').find('.checkbox-vis').removeClass('selected');
            }
        });
    })
    $('#contact-form').find('.form-element.checkbox').find('label').on('click', function (e) {
        if ($(e.target).is('small')) {
            //console.log(e.target)
        }
    })

})
